<template>
  <div class="NoColumns">
    <the-header />
    <div class="App">
      <brands-sidebar />
      <div class="App__content-wrapper">
        <div class="App__content container">
          <slot />
        </div>
      </div>
    </div>
    <clipping-preview-modal />
    <the-no-brands-dialog v-if="$store.getters.brands.length === 0" />
    <video-dialog />
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader'
import VideoDialog from '@/components/core/VideoDialog'
import BrandsSidebar from '@/components/sidebars/BrandsSidebar'
import TheNoBrandsDialog from '@/components/TheNoBrandsDialog'

/**
 * Renders the default Hypefactors layout with Sidebar and Navigation
 * @module NoColumns
 */
export default {
  name: 'NoColumns',

  components: {
    VideoDialog,
    TheNoBrandsDialog,
    TheHeader,
    BrandsSidebar
  }
}
</script>
